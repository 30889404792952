import { Menu } from '../menu.model';
import { FeatureFlippingKey } from '@terega-portal/shared-lib';

export const TRANSPORT_MENU: Menu = {
  name: 'TRANSPORT',
  icon: 'transport',
  items: [
    {
      name: 'OFFER',
      items: [
        {
          name: 'CONTRACTUAL_INFORMATION_TRANSPORT',
          link: '/edito/les-mecanismes-de-commercialisation-contrat-et-tarifs-de-terega',
        },
        {
          name: 'TRANSPORT_COMMERCIAL_OFFER_BOOKLET',
          resourceLink: {
            en: '/resources/offers/offre_transport_en.pdf',
            fr: '/resources/offers/offre_transport_fr.pdf',
          },
        },
        {
          name: 'MAIN_GRID',
          items: [
            {
              name: 'TRF',
              toggle: true,
              items: [
                {
                  name: 'TRADING_REGION_FRANCE_AND_CONGESTION_MANAGEMENT',
                  link: '/edito/les-mecanismes-de-decongestion-proposes-par-terega',
                },
              ],
            },
            {
              name: 'VIP_PIRINEOS',
              toggle: true,
              items: [
                {
                  name: 'CAPACITY_BOOKING_ON_THE_MAIN_GRID',
                  link: '/edito/comment-fonctionne-la-commercialisation-des-capacites-sur-le-reseau',
                },
                {
                  name: 'COMMERCIAL_FLEXIBILITY_AND_CMP_MECHANISMS',
                  link: '/edito/les-offres-de-terega-pour-la-gestion-de-la-congestion',
                },
                {
                  name: 'ADDITIONAL_SERVICES_OF_THE_UPSTREAM_OFFER_MAIN_GRID',
                  link: '/edito/les-services-additionnels-de-loffre-amont',
                },
              ],
            },
          ],
        },
        {
          name: 'REGIONAL_GRID',
          items: [
            {
              name: 'CAPACITY_BOOKING_ON_THE_REGIONAL_GRID',
              link: '/edito/reservations-de-capacites-sur-le-reseau-regional-de-terega',
            },
            {
              name: 'ADDITIONAL_SERVICES_OF_THE_DOWNSTREAM_OFFER_REGIONAL_GRID',
              link: '/edito/les-services-additionnels-de-loffre-aval',
            },
          ],
        },
      ],
    },
    {
      name: 'OPERATIONAL_DATA',
      items: [
        { name: 'NOMINATIONS', externalLink: '/star-app/logistique/nominations' },
        { name: 'PHYSICAL_FLOW_GAS_QUALITY', externalLink: '/star-app/logistique/qualite-gaz' },
        { name: 'TRANSPORT_MAINTENANCES_REDUCTIONS', externalLink: '/star-app/logistique/maintenances-et-reductions/transport' },
        {
          name: 'CAPACITIES_MONITORING',
          items: [
            {
              name: 'CAPACITIES',
              link: '/logistique/capacities',
              featureFlippingKey: FeatureFlippingKey.CAPACITIES,
            },
            {
              name: 'TRANSPORT_CESSIONS_PUBLIC',
              link: '/offer/transport-cessions/public',
              featureFlippingKey: FeatureFlippingKey.TRANSPORT_CESSIONS_PUBLIC,
            },
            {
              name: 'COMMERCIAL_CONGESTION',
              link: '/logistique/commercial-congestion',
              featureFlippingKey: FeatureFlippingKey.COMMERCIAL_CONGESTION,
            },
          ],
        },
        {
          name: 'ALLOCATIONS_MONITORING',
          items: [
            { name: 'ALLOCATED_QUANTITIES', link: '/logistique/allocations' },
            { name: 'PUBLIC_INTRADAY_DATA', link: '/logistique/intra-j' },
            {
              name: 'FORECASTS',
              toggle: true,
              items: [
                { name: 'PUBLIC_PIC_PITD_FORECAST', externalLink: '/star-app/logistique/forecast/pic-pitd' },
                { name: 'PUBLIC_PITD_FORECAST', link: '/logistique/forecast/public-pitd' },
              ],
            },
          ],
        },
        {
          name: 'BALANCING',
          items: [
            {
              name: 'INTRADAY_BALANCING',
              toggle: true,
              items: [
                {
                  name: 'TRANSPORT_PROGRAMMING_IMBALANCE',
                  externalLink: '/star-app/logistique/intra-day-balancing/transport-programming-imbalance',
                },
                {
                  name: 'ACTIONS_ON_MARKET',
                  link: '/logistique/intra-day-balancing/actions-on-market',
                },
                { name: 'LINEPACK', externalLink: '/star-app/logistique/stock-gaz-en-conduite' },
              ],
            },
            {
              name: 'TRANSPORT_ALLOCATIONS_BALANCING',
              toggle: true,
              items: [
                {
                  name: 'PURCHASES_SALES',
                  link: '/logistique/allocation-balancing/purchases-sales',
                },
                {
                  name: 'IMBALANCE_SETTLEMENT_PRICES',
                  link: '/logistique/allocation-balancing/imbalance-settlement-prices',
                },
              ],
            },
          ],
        },
        {
          name: 'VIGILANCE',
          items: [
            { name: 'VIGILANCE_D_TO_D5', link: '/logistique/vigilance' },
            { name: 'LOCATIONAL_SPREAD', link: '/logistique/locational-spread' },
            { name: 'WINTER_STORAGE_MONITORING', link: '/logistique/monitoring-stock-winter' },
          ],
        },
      ],
    },
    {
      name: 'DOCUMENTATIONS_TOOLS',
      items: [
        { name: 'TRANSPORT_TUTORIALS', link: '/edito/terega-vous-accompagne-dans-votre-gestion-operationnelle' },
        { name: 'SIMULATION_TOOLS', link: '/edito/terega-propose-des-outils-de-simulation-tarifaire' },
      ],
    },
    {
      name: 'REGULATORY_INFORMATION',
      items: [
        { name: 'BALANCING_NOTICE', link: '/edito/les-engagements-de-terega-sur-lavis-dequilibrage' },
        {
          name: 'INCREMENTAL_CAPACITY',
          link: '/edito/decouvrez-la-demarche-de-terega-en-matiere-de-capacite-incrementale',
        },
        {
          name: 'STORAGE_COMPENSATION',
          link: '/edito/terega-vous-detaille-les-principes-de-la-compensation-stockage-de-gaz',
        },
        { name: 'LIST_OF_GRID_DELIVERY_POINTS', link: '/edito/la-liste-des-points-de-livraison-du-reseau-terega' },
        {
          name: 'OPTIMIZATION_OF_TECHNICAL_CAPACITY',
          link: '/edito/expediteurs-pourquoi-loptimisation-de-la-capacite-technique',
        },
        { name: 'REMIT_PUBLICATION', link: '/edito/marche-de-gros-de-lenergie-restez-informes-avec-terega' },
        { name: 'SERVICE_QUALITY', link: '/edito/la-qualite-de-service-terega' },
        {
          name: 'REDISTRIBUTION_OF_AUCTION_PREMIUM',
          link: '/edito/vip-pirineos-la-redistribution-des-excedents-dencheres-par-terega',
        },
        { name: 'IMBALANCE_SETTLEMENT', link: '/edito/la-methodologie-de-terega-pour-le-reglement-des-desequilibres' },
        { name: 'GRIP', link: '/edito/plan-regional-dinvestissement-gaz-grip' },
        {
          name: 'REDUCTION_RATES_AND_SCHEDULED_MAINTENANCE',
          link: '/edito/taux-de-reduction-et-maintenances-programmees-la-methode-des-operateurs',
        },
        {
          name: 'ENTSOG_TEMPLATE',
          link: '/edito/tarifs-dutilisation-des-reseaux-de-transport-de-gaz-le-modele-entsog',
        },
      ],
    },
  ],
};
