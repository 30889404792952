<nav class="menu"
     [@openCloseMenu]="mouseOverMenu || !!selectedMenu ? 'open' : 'closed'"
     (mouseenter)="mouseOverMenu = true" (mouseleave)="mouseOverMenu = false">
  <div class="terega">
    <mat-icon [svgIcon]="'terega-logo'" class="logo"></mat-icon>
    <mat-icon *ngIf="mouseOverMenu || !!selectedMenu" [svgIcon]="'terega-title'" class="title"></mat-icon>
  </div>
  <ul (mouseleave)="hoveredMenu = null">
    <ng-container *ngTemplateOutlet="linkedMenuItem; context: { params: {name: 'HOMEPAGE', icon: 'menu-homepage', link: '/' } }"></ng-container>
    <ng-container *ngFor="let menu of menus">
      <ng-container *starIsAllowed="menu?.authorizedProfiles">

        <li
          *starIsAllowedDelegation="menu?.authorizedDelegations"
          class="item"
          [ngClass]="{'active-link': (rla.isActive && !mouseOverMenu && !selectedMenu) || menu?.name === (selectedMenu || hoveredMenu)?.name}"
          (mouseenter)="hoveredMenu = menu"
          (click)="selectedMenu = menu; onMenuStateChange.emit(true)"
          routerLinkActive #rla="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }">
          <mat-icon [svgIcon]="'menu-' + menu.icon"></mat-icon>
          <span *ngIf="mouseOverMenu || !!selectedMenu">{{ menu.name }}</span>

          <ul class="sub-menu" [@openCloseSubMenu]="menu?.name === selectedMenu?.name ? 'open' : 'closed'">
            <ng-container *ngTemplateOutlet="subMenu; context: { $implicit: menu?.items }"></ng-container>

            <ng-template #subMenu let-items>
              <ng-container *ngFor="let subMenu of items">
                <ng-container *ngTemplateOutlet="menu; context: { $implicit: subMenu, level: 1 }"></ng-container>
                <ng-container *ngTemplateOutlet="link; context: { $implicit: subMenu }"></ng-container>
              </ng-container>

              <ng-template #menu let-subMenu let-level="level">
                <ul *ngIf="(subMenu?.items && !subMenu?.toggle)">
                  <li *ngIf="subMenu?.name">
                    <h2 *ngIf="subMenu?.items && level === 1">{{ subMenu.name | uppercase }}</h2>
                    <h3 *ngIf="subMenu?.items && level === 2">{{ subMenu.name | uppercase }}</h3>
                  </li>
                  <li>
                    <ng-container *starIsAllowed="subMenu?.authorizedProfiles">
                      <ng-container *ngFor="let item of subMenu?.items">
                        <ng-container *starIsAllowedDelegation="subMenu?.authorizedDelegations">
                          <ng-container *ngTemplateOutlet="menu; context: { $implicit: item, level: 2 }"></ng-container>
                          <ng-container
                            *ngTemplateOutlet="toggleMenu; context: { $implicit: item, level: level }"></ng-container>
                          <ng-container *ngTemplateOutlet="link; context: { $implicit: item }"></ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </li>
                </ul>
              </ng-template>

              <ng-template #toggleMenu let-item let-level="level">
                <ng-container *starIsAllowed="item?.authorizedProfiles">
                  <ng-container *ngIf="item?.toggle">
                    <div class="toggle-link" (click)="item.visible = (!item?.visible)">
                      <a>{{ item?.name }}</a>
                      <span class="material-icons">{{ item.visible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</span>
                    </div>
                    <div class="toggle-menu" *ngIf="item?.visible"
                         [style.margin-left]="level === 1 ? '0px' : '-15px'">
                      <ng-container *ngFor="let toggleLink of item?.items">
                        <ng-container *ngTemplateOutlet="link; context: { $implicit: toggleLink }"></ng-container>
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-template>

              <ng-template #link let-item>
                <ng-container *flippable="item?.featureFlippingKey">
                  <ng-container *ngIf="item?.link || item?.tetraLink || item?.externalLink || item?.resourceLink">
                    <li *starIsAllowed="item?.authorizedProfiles">
                      <a *ngIf="item?.link" [routerLink]="item?.link" (click)="$event.stopPropagation()">{{ item?.name }}</a>
                      <a *ngIf="item?.resourceLink" [href]="item?.resourceLink[lang]" target="_blank">{{ item?.name }}</a>
                      <a *starExternalLink="item" [href]="item?.tetraLink ? (item.tetraLink | tetraLink: lang) : item?.externalLink" [target]="migratedPages(item?.externalLink)">
                        {{ item?.name }}
                        <mat-icon *ngIf="item?.tetraLink" class="tetra-link-icon" svgIcon="menu-external-link"></mat-icon>
                      </a>
                    </li>
                  </ng-container>
                </ng-container>
              </ng-template>
            </ng-template>

          </ul>
        </li>
      </ng-container>
    </ng-container>
    <ng-container *ngTemplateOutlet="linkedMenuItem; context: { params: {name: 'CONTACT', icon: 'menu-contact', link: '/edito/contacts' } }"></ng-container>
    <ng-template #linkedMenuItem let-params='params'>
      <li class="item" routerLinkActive #rla="routerLinkActive" (mouseenter)="hoveredMenu = { name: params.name }" (click)="selectedMenu = rla.isActive ? null : { name: params.name }"
          [ngClass]="{'active-link': (rla.isActive && !mouseOverMenu && !selectedMenu) || params.name === (selectedMenu || hoveredMenu)?.name}"
          [routerLink]=params.link [routerLinkActiveOptions]="{ exact: true }">
        <mat-icon [svgIcon]=params.icon></mat-icon>
        <span *ngIf="mouseOverMenu || !!selectedMenu">{{ ('UI_MENU.' + params.name) | translate}}</span>
      </li>
    </ng-template>
  </ul>
  <ul class="menu__additional">
    <li><ng-content></ng-content></li>
  </ul>
</nav>
